import { createApiRef } from '@backstage/core-plugin-api';
import { FetchApi, ErrorApi, ConfigApi } from '@backstage/core-plugin-api';

type GitHubRepoApiOptions = {
  errorApi: ErrorApi;
  fetchApi: FetchApi;
  configApi: ConfigApi;
};

export const githubRepoApiRef = createApiRef<GitHubRepoApi>({
  id: 'plugin.frontend-common-react.github-repo-api',
});

export type GitHubRepoApi = ReturnType<typeof createGithubRepoApi>;

export const createGithubRepoApi = ({ errorApi, configApi, fetchApi }: GitHubRepoApiOptions) => {
  const backendUrl = configApi.getString('backend.baseUrl');
  const actualBackendUrl = backendUrl.replace('/backend', '');

  const fetchJsonContent = async <T extends any>(
    gitRepoUrl: string,
    branch: string,
    filePath: string,
  ): Promise<T> => {
    if (!gitRepoUrl || !branch || !filePath) {
      throw new Error('Repository details are not provided.');
    }

    const apiUrl = `${actualBackendUrl}/api/platform/fetch-content`;
    const queryParams = new URLSearchParams({
      gitRepoUrl,
      branch,
      filePath,
    });

    try {
      const response = await fetchApi.fetch(`${apiUrl}?${queryParams}`);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const content = await response.json();

      return content as T;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred.';
      errorApi.post(new Error(`Failed to fetch or parse the JSON. ${errorMessage}`));
      throw error;
    }
  };

  return {
    fetchJsonContent,
  };
};
