import { ScmIntegrationsApi, scmIntegrationsApiRef, ScmAuth } from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  analyticsApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { GoogleAnalytics4 } from '@backstage-community/plugin-analytics-module-ga4';

import { TechRadarClient } from './TechRadarClient';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { githubRepoApiRef } from '@internal/backstage-plugin-frontend-common-react/src/api';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),

  // Instantiate and register the GA Analytics API Implementation.
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      GoogleAnalytics4.fromConfig(configApi, {
        identityApi,
      }),
  }),

  createApiFactory({
    api: techRadarApiRef,
    deps: {
      githubRepoApi: githubRepoApiRef,
    },
    factory: ({ githubRepoApi }) => new TechRadarClient(githubRepoApi),
  }),
];
