import {
  createApiFactory,
  createPlugin,
  fetchApiRef,
  errorApiRef,
  configApiRef,
} from '@backstage/core-plugin-api';

import { githubRepoApiRef, createGithubRepoApi } from './api/github-repo';

export const frontendCommonPlugin = createPlugin({
  id: 'frontend-common-react',
  apis: [
    createApiFactory({
      api: githubRepoApiRef,
      deps: { fetchApi: fetchApiRef, errorApi: errorApiRef, configApi: configApiRef },
      factory: ({ fetchApi, configApi, errorApi }) =>
        createGithubRepoApi({ fetchApi, configApi, errorApi }),
    }),
  ],
});
