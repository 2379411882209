import { DiscoveryApi, createApiRef, FetchApi } from '@backstage/core-plugin-api';
import {
  GetUsersResponse,
  GetUsageResponse,
  GetSourcesResponse,
} from '@internal/backstage-plugin-license-common';

type GithubCopilotApiOptions = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
};

export const githubCopilotApiRef = createApiRef<GithubCopilotApi>({
  id: 'plugin.license.github.copilot',
});

export type GithubCopilotApi = ReturnType<typeof githubCopilotApi>;

export const githubCopilotApi = ({ discoveryApi, fetchApi }: GithubCopilotApiOptions) => {
  const getUsers = async (source: string) => {
    const BASE_URL = await discoveryApi.getBaseUrl('license');

    const url = new URL(`${BASE_URL}/github/copilot/users`);

    url.searchParams.set('source', source);

    const response = await fetchApi.fetch(url.toString());

    return response.json() as Promise<GetUsersResponse>;
  };

  const getUsage = async (source: string) => {
    const BASE_URL = await discoveryApi.getBaseUrl('license');

    const url = new URL(`${BASE_URL}/github/copilot/usage`);

    url.searchParams.set('source', source);

    const response = await fetchApi.fetch(url.toString());

    return response.json() as Promise<GetUsageResponse>;
  };

  const getSources = async () => {
    const BASE_URL = await discoveryApi.getBaseUrl('license');

    const url = new URL(`${BASE_URL}/github/copilot/sources`);

    const response = await fetchApi.fetch(url.toString());

    return response.json() as Promise<GetSourcesResponse>;
  };

  return {
    getUsers,
    getUsage,
    getSources,
  };
};
