import { useMemo } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { errorApiRef } from '@backstage/core-plugin-api';
import { githubRepoApiRef } from '../api';

export const useFetchJsonFromGHRepo = () => {
  const errorApi = useApi(errorApiRef);
  const githubRepoApi = useApi(githubRepoApiRef)


  return useMemo(() => githubRepoApi.fetchJsonContent, [errorApi]);
};
